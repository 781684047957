import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet, useSearchParams } from "react-router-dom";
import ChatProvider from "_common/component/ChatProvider";
import { AuthApi } from "states/api";
import { useQuery } from "@tanstack/react-query";
import NotifyProvider from "_common/component/NotifyProvider";

const ClientSupportLayout = observer(() => {
  const { authStore } = useStores();
  const [params] = useSearchParams();
  const token = params.get("token");

  useQuery(["get_profile"], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!token,
    retry: 3,
    onSuccess: (res) => {
      authStore.updateUser({ user: res?.data });
    },
  });

  useEffect(() => {
    if (!token) return;
    authStore.updateToken(token);
  }, [token]);

  return (
    <ChatProvider user={authStore?.user}>
      <NotifyProvider>
        <Outlet />
      </NotifyProvider>
    </ChatProvider>
  );
});

export default ClientSupportLayout;
