import moment from "moment";
import React, { useState } from "react";
import MenuChat from "../MenuChat";
import ModalImage from "./ModalImage";

function MessageRight({ item }) {
  const [openModal, setOpenModal] = useState();

  return (
    <li className="chat-list right" id="2">
      <div className="conversation-list">
        <div className="user-chat-content">
          <div className="ctext-wrap">
            <div className="ctext-wrap-content" id="2">
              {item?.file_path && (
                <img
                  role="presentation"
                  onClick={() => setOpenModal(true)}
                  src={item?.file_path}
                  alt=""
                />
              )}
              <p className="mb-0 ctext-content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: item?.message?.split("\n").join("</br>"),
                  }}
                />
                {item?.status === "edit" && (
                  <span className="edit-chat">Đã sửa</span>
                )}
              </p>
            </div>
            {item?.user_id && <MenuChat item={item} />}
          </div>
          <div className="conversation-name">
            <small className="text-muted time">
              {moment(item?.created_at).format("DD/MM HH:mm")}
            </small>
            <span className="text-success check-message-icon">
              <i className="bx bx-check-double" />
            </span>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalImage
          image={item?.file_path}
          onClose={() => setOpenModal(false)}
        />
      )}
    </li>
  );
}

export default MessageRight;
